export const downloadFile = (content, mimeType, fileName) => {
  // create a blob and download it
  let blob = new Blob([content], { type: mimeType })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', fileName)
  link.click()

  link.remove()
  URL.revokeObjectURL(url)
  blob = null
}
